export interface ExtendedLocale {
  code: string;
  iso: string;
  file: string;
  name: string;
}
export function castEnv(value: string, defaultValue: unknown = null) {
  if (typeof value === 'undefined' || value === null) {
    return defaultValue;
  }

  if (!value) {
    return defaultValue;
  }

  switch (true) {
    case value.toLowerCase() === 'null': {
      return null;
    }

    case value.toLowerCase() === 'true': {
      return true;
    }

    case value.toLowerCase() === 'false': {
      return false;
    }

    case String(value).length > 1 && String(value).startsWith('0'): {
      return String(value);
    }

    case !Number.isNaN(value): {
      return parseFloat(value);
    }

    default: {
      return String(value);
    }
  }
}

export function getLocaleMapFromEnv(envMap = import.meta.env.VITE_LOCALE_CODE_NAME_MAP) {
  const fallbackMap = 'ru:Русский';

  if (!envMap) {
    return fallbackMap;
  }

  const split = envMap.split(';').filter((el: string) => {
    const innerSplit = el.split(':');

    return innerSplit.length === 2 && innerSplit.every((part) => part.length > 0);
  });

  return split ? split.join(';') : fallbackMap;
}

export function getI18nLocales(localeMap: string): ExtendedLocale[] {
  const split = localeMap.split(';');

  return split.map((el) => {
    const innerSplit = el.split(':');

    const code = innerSplit[0];
    const name = innerSplit[1];

    return {
      code,
      iso: code,
      file: `${code}.js`,
      name,
    };
  });
}

export function getDefaultLocaleFromMap(localeMap: string): string {
  const split = localeMap.split(';');
  const fallbackLocale = 'ru';

  return split[0] ? split[0].split(':')[0] : fallbackLocale;
}

export function getEnvUniversal(...keys: string[]): string {
  for (const key of keys) {
    const value: string | undefined = import.meta.env ? import.meta.env[key] : process.env[key];

    if (value) {
      return value;
    }
  }

  return '';
}
